<template>
  <div class="contenedor-difusion">
    <!-- <Header2/> -->
    <section class="contenedor-c">
      <div class="contenedor-titulo">
        <h1>Seminario de Yoga Iyengar con Uday Bhosale</h1>
        <div class="barra"></div>
      </div>
      <section class="contenedor-login">
        <div class="contenedor-difusion-imagen">
          <img src="@/assets/uday.jpeg" alt="Uday Bhosale">
        </div>
        <div class="contenedor-difusion-text">
          <!-- <p>Con inmensa alegría te invitamos a participar de nuestra <b>Convención Anual Nacional de Yoga Iyengar</b> del <b>24 al 28 de mayo de 2024</b>. Es la primera Convención presencial luego de la pandemia y estamos felices de poder reencontrarnos en persona. El <b>profesor invitado de lujo</b> en esta oportunidad será <b>Raya UD</b>, discípulo directo de <b>BKS Iyengar</b> y maestro senior del <b>RIMYI</b>, el Instituto Iyengar en Pune, India.</p>

          <p>La <b>Convención</b> constará de <b>dos partes</b>: una parte para <b>profesores</b>, personas en <b>mentoría</b> y personas con más de <b>5 años de práctica</b> (con carta de recomendación), y dos días para <b>alumnos</b> en general con <b>1 año de clases.</b></p>
          <p>Para <b>profesorxs, personas en mentoría y alumnos</b> con <b>5 años de práctica</b>: viernes <b>24</b>, lunes <b>27</b> y martes <b>28 de mayo 2024</b>.</p>
          <p><b>General</b> (<b>alumnos</b> con mínimo de <b>1 año de clases</b> regulares): sábado <b>25</b> y domingo <b>26 de mayo 2024</b>.</p>
          <p>⚠️ Para asistir el viernes, lunes y martes es condición asistir también el fin de semana. Pero si, por el contrario, profesores, mentoreados, etc necesitan asistir solamente el fin de semana, pueden hacerlo. ⚠️</p>

          <p>👀 Invitamos enfáticamente a la comunidad profesores y personas formándose a participar de este evento único y tan enriquecedor para nuestra comunidad. 👀</p> -->

          <p>Luego del hermoso encuentro con <b>Raya Una Datta</b>, se viene otro gran evento para nuestra comunidad. <b>Uday Bhosale</b> vendrá a la Argentina en el marco de su gira por América<b> "La Alegría del Yoga Iyengar"</b> (The Joy of Iyengar Yoga).</p>

          <p>Uday Bhosale vive en Reading, Reino Unido. Da clases para todos los niveles y capacidades en Reading, Londres, y Henley-on-Thames. Su maestro de artes marciales lo introdujo al <b>método Iyengar</b>. Comenzó aprendiendo con <b>Guruji, Geetaji y Prashantji</b> en 1999. En el <b>Instituto de Yoga Iyengar</b> en Pune, condujo las clases para niños (7-15 años), clases para alumnos intermedios y principiantes. Asistió regularmente en las clases médicas (terapéuticas) en RIMYI, que se llevaban a cabo 3 veces por semana. En 2015 fue maestro invitado en la <b>21a Convención Nacional Francesa de Yoga Iyengar</b>. Desde entonces ha conducido talleres y retiros en el Reino Unido, Francia, Irlanda, Alemania. <b>Uday Bhosale</b> fue enviado a China por el gobierno de India para el Día Internacional del Yoga en 2016, en representación del <b>RIMYI</b>. Uday ha conducido Workshops de Yoga por todo el Reino Unido y por toda Europa.</p>

          <h2>Seminario General (con solo 1 año o más de práctica)</h2>
          <p><b>Fechas: 28 & 29 de Septiembre</b> 2024</p>

            <p class="margin_left_uday">Sábado 28: 9:30-12 hs</p>
            <p class="margin_left_uday">Domingo 29: 9:30-12 hs</p>
          <p><b>Precio:</b> Un pago de $77.500 o dos pagos (Agosto y Septiembre) de $ 38.750</p>
          <h2>Seminario Completo (4 años o más de práctica)</h2>
          <p><b>Fechas: 27, 28 & 29 de Septiembre</b> 2024</p>

          <p class="margin_left_uday">Viernes 27: 16:30-19:30 hs</p>
          <p class="margin_left_uday">Sábado 28: 9:30-12 hs y 14-16:30 hs</p>
          <p class="margin_left_uday">Domingo 29: 9:30-12 hs</p>
          <p><b>Precio:</b> Un pago de $170.500 o dos pagos (Agosto y Septiembre) de $ 85.250</p>
          <p><b>Lugar:</b> Facultad de Ciencias Económicas, Uriburu 781, CABA</p>
          <p><b>Importante:</b> Una vez hecho el pago parcial o total la Asociación no puede hacer devoluciones</p>
          <BaseLoader
            v-if="cargando"
          />
          <div v-else>
            <div class="seminario-price-box" v-if="mostrarBotonesSeminario">
              <!-- <h2>1) COMPLETA EL FORMULARIO ANTES DE PAGAR</h2>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSfjmNxgcHJUPOVNY1ybmdDOMpDfIIymBfDxS4bYJAaQT4BBMA/viewform" target="_blank"  class="seminario-price-box-boton-form"><b>Formulario</b></a>
              <div class="contenedor_checkbox">
                <input
                  type="checkbox"
                  id="form"
                  v-model="form"
                  @change="ckeckFormulario()"
                >
                <label for="form">Confirmo que he completado el formulario</label>
              </div>
              <p class="terminos_condiciones_mensaje_error" v-if="mostrarMensajeFormulario">Por favor, completa el formulario antes de pagar</p> -->
              <h2> REALIZAR EL PAGO</h2>
              <p v-if="mostrar" class="warning_no_profe">Por favor, completa el DNI y el domicilio en tu perfil para poder realizar tu pago</p>
              <p v-if="mostrarMensajeFaltaCuotaDos" class="text_cuota_2">{{ this.textoFaltaCuotaDos }}</p>
              <ul>
                <li v-if="mostrarBotonesSeminarioGeneral">
                  <p><b>Seminario General</b></p>
                  <ul>
                    <li v-if="mostrarBotonesNoCuotaDosSeminarioGeneral"><span><button @click="pagarSaminario(20)">Pago Total <br>(AR$ 77.500)</button></span></li>
                    <li v-if="mostrarBotonesNoCuotaDosSeminarioGeneral"><span><button @click="pagarSaminario(21)">Pagar Cuota #1 <br>(AR$ 38.750)</button></span></li>
                    <li v-if="mostrarBotonCuotaDosSeminarioGeneral"><span><button @click="pagarSaminario(22)">Pagar Cuota #2 <br>(AR$ 38.750)</button></span></li>
                  </ul>
                </li>
                <li v-if="mostrarBotonesSeminarioCompleto">
                  <p><b>Seminario Completo</b></p>
                  <ul>
                    <li v-if="mostrarBotonesNoCuotaDosSeminarioCompleto"><span><button @click="pagarSaminario(23)">Pago Total <br>(AR$ 170.500)</button></span></li>
                    <li v-if="mostrarBotonesNoCuotaDosSeminarioCompleto"><span><button @click="pagarSaminario(24)">Pagar Cuota #1 <br>(AR$ 85.250)</button></span></li>
                    <li v-if="mostrarBotonCuotaDosSeminarioCompleto"><span><button @click="pagarSaminario(25)">Pagar Cuota #2 <br>(AR$ 85.250)</button></span></li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="seminario-texto-box" :class="clase" v-else>
              {{ this.texto }}
            </div>
          </div>
          <p>Consultas a <a href="mailto:seminarios@asociacioniyengar.com.ar" target="_blank"><b>seminarios@asociacioniyengar.com.ar</b></a></p>
        </div>
      </section>
    </section>
  </div>
</template>

<script>
import pagosService from '../services/pagos'
import asociadoService from '../services/asociado'
import BaseLoader from '../components/BaseLoader.vue'
const mercadopago = require('mercadopago')
export default {
  name: 'SeminarioUdaySocio',
  components: {
    BaseLoader
  },
  data: function () {
    return {
      form: false,
      cargando: false,
      texto: null,
      clase: null,
      textoFaltaCuotaDos: 'ffffff',
      mostrarMensajeFormulario: false,
      mostrarBotonesSeminario: true,
      mostrarBotonesSeminarioCompleto: true,
      mostrarBotonesNoCuotaDosSeminarioCompleto: true,
      mostrarBotonCuotaDosSeminarioCompleto: false,
      mostrarBotonesSeminarioGeneral: true,
      mostrarBotonCuotaDosSeminarioGeneral: false,
      mostrarBotonesNoCuotaDosSeminarioGeneral: true,
      mostrarMensajeFaltaCuotaDos: false,
      mostrar: false,
      socio: [],
      linkMercadoPago: {
        link: null
      },
      user: {
        tipo_id: JSON.parse(localStorage.getItem('userData')).tipo_id,
        persona_id: JSON.parse(localStorage.getItem('userData')).persona_id,
        nombre: JSON.parse(localStorage.getItem('userData')).nombre,
        apellido: JSON.parse(localStorage.getItem('userData')).apellido,
        dni: JSON.parse(localStorage.getItem('userData')).dni,
        direccion_1: JSON.parse(localStorage.getItem('userData')).direccion_1,
        provincia_id_1: JSON.parse(localStorage.getItem('userData')).provincia_id_1
      }
    }
  },
  methods: {
    ckeckFormulario () {
      if (this.form === false) {
        this.mostrarMensajeFormulario = true
      } else {
        this.mostrarMensajeFormulario = false
      }
    },
    pagarSaminario (cuotaId) {
      console.log(cuotaId)
      // if (this.form === false) {
      //   this.mostrarMensajeFormulario = true
      //   return
      // }
      if (this.socio.dni == null || this.socio.direccion_1 == null) {
        this.mostrar = true
      } else {
        pagosService
          .traerCuotaPorId(cuotaId)
          .then(respuesta => {
            // this.cargando = false
            // this.cuotasPagas = respuesta
            console.log('Cuota:', respuesta)
            const preference = {
              // trackingId: 77,
              back_urls: {
                failure: 'https://portal.asociacioniyengar.com.ar/#/pago-rechazado',
                success: 'https://portal.asociacioniyengar.com.ar/#/pago-exitoso'
              },
              items: [
                {
                  // id: respuesta.concepto_id,
                  title: String(respuesta[0].nombre),
                  // unit_price: 44,
                  unit_price: Number(respuesta[0].precio),
                  quantity: 1
                }
              ],
              metadata: {
                persona_id: this.socio.persona_id,
                nombre: this.socio.nombre,
                apellido: this.socio.apellido,
                email: this.socio.email,
                direccion: this.socio.direccion_1,
                provincia: this.socio.provincia_id_1,
                dni: this.socio.dni,
                concepto_id: 5,
                forma_de_pago_id: 3,
                moneda_id: 1,
                servicio_id: Number(respuesta[0].servicio_id),
                cuota_id: Number(respuesta[0].cuota_id),
                pago_concepto: 'Seminario Uday',
                descripcion: String(respuesta[0].nombre),
                fecha_inicio: String(respuesta[0].fecha_inicio),
                fecha_finalizacion: String(respuesta[0].fecha_finalizacion),
                precio: Number(respuesta[0].precio)
                // precio_total: Number(respuesta[respuesta.length - 1].precio_total),
                // descripcion: 'Pago Cuota Social ' + new Date().getFullYear(),
                // year: new Date().getFullYear()
              },
              payer: {
                name: this.socio.nombre,
                surname: this.socio.apellido
              },
              notification_url: 'https://api.asociacioniyengar.com.ar/api/webhooks',
              // notification_url: 'https://c74e-190-194-214-138.ngrok-free.app/AAYI_API/api/public/api/webhooks',
              binary_mode: true,
              auto_return: 'approved',
              external_reference: this.socio.nombre + ' ' + this.socio.apellido + ' - ' + new Date().getTime().toString(),
              statement_descriptor: 'Asociación Argentina de Yoga Iyengar'
            }
            mercadopago.configure({
              access_token: 'APP_USR-6447832264591121-032711-ad6690b0c38d8e10440c46daab8b7204-1323684790'
              // Credenciales de Prueba
              // access_token: 'APP_USR-2339860730955398-032713-925884898e35a8f3be76c84328e45b4f-1339687465'
            })
            mercadopago.preferences
              .create(preference)
              .then(response => {
                console.log('mercadopago: ', response.body)
                console.log(new Date().getTime())
                // console.log(this.linkMercadoPago.link)
                this.linkMercadoPago.link = response.body.init_point
                // En esta instancia deberás asignar el valor dentro de response.body.id por el ID de preferencia solicitado en el siguiente paso
                window.location.href = this.linkMercadoPago.link
              })
              .catch(function (error) {
                console.log('MP ERROR!!!!!')
                console.log(error)
              })
          })
      }
    }
  },
  mounted: function () {
    this.cargando = true
    asociadoService
      .traerSocioPorId(this.user.persona_id)
      .then(respuesta => {
        this.socio = respuesta
        console.log('Socio:', respuesta)
      })
    pagosService
      .validarSocioAlDia(this.user.persona_id)
      .then(respuesta => {
        this.cargando = false
        console.log('Validar Socio:', respuesta)
        if (respuesta.success) {
          this.mostrarBotonesSeminario = true
        } else {
          this.mostrarBotonesSeminario = false
          this.texto = respuesta.mensaje
          this.clase = 'fondo-rojo'
        }
      })
    pagosService
      .checkPago(this.user.persona_id)
      .then(respuesta => {
        console.log('Pago:', respuesta.data)
        if (respuesta.data.mostrar_botones === 'no') {
          this.mostrarBotonesSeminario = false
          this.texto = respuesta.data.mensaje
        }
        if (respuesta.data.mostrar_botones === 'si') {
          if (respuesta.data.seminario === 'completo') {
            this.mostrarBotonesSeminarioGeneral = false
            this.mostrarBotonCuotaDosSeminarioCompleto = true
            this.mostrarBotonesNoCuotaDosSeminarioCompleto = false
          } else {
            this.mostrarBotonesSeminarioCompleto = false
            this.mostrarBotonCuotaDosSeminarioGeneral = true
            this.mostrarBotonesNoCuotaDosSeminarioGeneral = false
          }
          this.mostrarMensajeFaltaCuotaDos = true
          this.textoFaltaCuotaDos = respuesta.data.mensaje
        }
      })
  }
}
</script>
